const get = require('lodash.get');

const configHelpers = require('./helpers');
const config = require('../../../config');

const defaultConfig = {
    adapters: {
        adobeAnalytics: {
            maps: {
                populators: {
                    trackingServer: () => get(config, 'endpoints.adobeAnalytics'),
                    trackingServerSecure: () => get(config, 'endpoints.secureAdobeAnalytics'),
                    account: event => {
                        const url = get(event, 'page.url.canonical');
                        if (url.indexOf('www.sky.com/watch') !== -1) {
                            return 'bskybfandwtv,bskybnetworkglobal,bskybeexperienceglobal';
                        } else if (url.indexOf('www.sky.com/ordertracking') !== -1) {
                            return 'bskybdtmskycomprod,bskybskyhcprod,bskybskyhcincl,bskybnetworkglobal,bskybeexperienceglobal';
                        } else if (url.indexOf('www.sky.com/help') !== -1) {
                            return 'bskybdtmskycomprod,bskybskyhcprod,bskybskyhcincl,bskybnetworkglobal,bskybeexperienceglobal';
                        } else if (url.indexOf('www.sky.com/mobile-account') !== -1 || url.indexOf('www.sky.com/myaccount/mobile') !== -1) {
                            return 'bskybskymobilewebprod,bskybeexperienceglobal,bskybnetworkglobal';
                        } else if (url.indexOf('www.sky.com/signin') !== -1) {
                            return 'bskybsiskyid,bskybnetworkglobal';
                        } else if (url.indexOf('www.sky.com') !== -1) {
                            return 'bskybhp,bskybnetworkglobal,bskybeexperienceglobal';
                        } else if (url.indexOf('mobile-account') !== -1 || url.indexOf('myaccount/mobile') !== -1) {
                            return 'bskybskymobilewebdev,bskybnetworkglobaldev';
                        } else {
                            return 'bskybfandwtvdev';
                        }
                    },
                    movieRecommendationABTest: event => get(event, 'page.custom.experiments.movieRecommendation'),
                    linkClick: event => {
                        const ctaConfirmationEvents = [
                            'nevermissSuccess',
                            'recordSuccess',
                            'recordSeriesSuccess',
                            'downloadSuccess'
                        ];

                        const eventAction = get(event, 'element.custom.eventAction');
                        const isCtaConfirmation = ctaConfirmationEvents.indexOf(eventAction) !== -1;
                        const isLinkClick = event.type === 'click';

                        return isLinkClick && !isCtaConfirmation;
                    },
                    trackingId: event => get(event, 'user.trackingId'),
                    panelName: event => get(event, 'page.custom.panelName'),
                    adobeTntValues: event => get(event, 'page.custom.adobeTarget.tntValues'),
                    adobeTntValuesOnClick: event => get(event, 'element.custom.adobeTarget.tntVal'),
                    skyChannel: event => get(event, 'page.custom.skyChannel'),
                    video: event => get(event, 'page.custom.video'),
                    userType: event => get(event, 'user.custom.segment.userType'),
                    countryCode: event => get(event, 'user.geoRegion'),
                    abTestVariant: event => {
                        const experiments = get(event, 'page.custom.experiments');

                        if (experiments) {
                            return Object.keys(experiments).map(key => `${key}=${experiments[key]}`).join('|');
                        }

                        return false;
                    },
                    myAccountDashboard: event => {
                        const nbasData = get(event, 'page.custom.nbas');
                        const productUpgrade = get(event, 'page.custom.productUpgrade');
                        const packageUpgrade = get(event, 'page.custom.packageUpgrade');

                        if (nbasData) {
                            const nbas = nbasData.join('|');

                            const upgrades = [productUpgrade, packageUpgrade]
                                .filter(p => p)
                                .join('|');

                            const nbasSection = `s:mad-nbas|${nbas}`;
                            const upgradesSection = upgrades ? `s:mad-upgrades|${upgrades}` : null;

                            return [nbasSection, upgradesSection]
                                .filter(s => s)
                                .join('|');
                        }

                        return false;
                    },
                    prospectSections: event => {
                        const sections = get(event, 'page.custom.prospectSections');
                        return sections;
                    },
                    tileListEvent: event => get(event, 'page.custom.tileList'),
                    tileList: event => {
                        const result = get(event, 'element.custom.tileList') || get(event, 'page.custom.tileList');

                        if (Array.isArray(result)) {
                            return result.join('|');
                        }

                        return result;
                    },
                    videoPlayed: event => get(event, 'page.custom.video'),
                    prospectTilesLoaded: event => get(event, 'page.custom.prospectTilesLoaded'),
                    searchTerm: event => get(event, 'page.custom.search.searchTerm'),
                    sid: event => get(event, 'page.custom.search.sidProbabilities'),
                    redefinedSearchTerm: event => get(event, 'page.custom.search.redefinedSearchTerm'),
                    results: event => get(event, 'page.custom.search.tileResults'),
                    numberSearchResultsDisplayed: event => get(event, 'page.custom.search.numberSearchResultsDisplayed'),
                    searchSuccess: event => get(event, 'page.custom.search.type') === 'searchSuccess',
                    searchFailed: event => get(event, 'page.custom.search.type') === 'searchFailed',
                    redefinedSearch: event => {
                        const searchType = get(event, 'page.custom.search.type');
                        return get(event, 'page.custom.search.redefinedSearch') && (searchType === 'searchFailed' || searchType === 'searchSuccess');
                    },
                    resultClicked: event => get(event, 'element.custom.eventAction') === 'resultClicked',
                    bouncedSearch: event => get(event, 'page.custom.search.type') === 'bouncedSearch',
                    watchCtaData: event => {
                        let ctaEvent;
                        const isWatchCta = eventData => {
                            if (eventData.element && eventData.element.custom) {
                                ctaEvent = eventData;
                                return [
                                    'nevermissSuccess',
                                    'recordSuccess',
                                    'recordSeriesSuccess',
                                    'downloadSuccess',
                                    'watchClicked'
                                ].indexOf(eventData.element.custom.eventAction) !== -1;
                            }
                        };

                        if (isWatchCta(event)) {
                            const channel = get(event, 'page.custom.skyChannel');
                            const slug = get(ctaEvent, 'element.custom.panelName');
                            const subSlug = get(ctaEvent, 'element.custom.subSlug');
                            const episodeData = get(ctaEvent, 'element.custom.episodeData');
                            const ctaInfo = [configHelpers.pageName(get, event).join(':'), channel, slug];

                            if (episodeData.season && episodeData.episode) {
                                ctaInfo.push(episodeData.season, episodeData.episode);
                            }

                            if (subSlug) {
                                ctaInfo.push(subSlug);
                            }

                            return ctaInfo.join('|');
                        }
                    },
                    originalBundleCustomer: configHelpers.hasPackage(get, 'original'),
                    varietyBundleCustomer: configHelpers.hasPackage(get, 'variety'),
                    familyBundleCustomer: configHelpers.hasPackage(get, 'family'),
                    sportsCustomer: configHelpers.hasPackage(get, 'sports'),
                    moviesCustomer: configHelpers.hasPackage(get, 'movies'),
                    multiscreenCustomer: configHelpers.hasPackage(get, 'multiscreen'),
                    skyGoExtraCustomer: event => get(event, 'user.custom.skygoextra'),
                    agentId: event => get(event, 'page.custom.agentId')
                },
                variables: {
                    trackingId: ['eVar56', 'prop62'],
                    panelName: ['prop3', 'eVar13'],
                    adobeTntValues: ['prop13', 'eVar86', 'tnt'],
                    skyChannel: ['prop38', 'eVar34'],
                    video: ['prop26', 'eVar28'],
                    userType: ['eVar12'],
                    countryCode: ['eVar3'],
                    abTestVariant: ['eVar18'],
                    myAccountDashboard: ['eVar82'],
                    tileList: ['list3'],
                    movieRecommendationABTest: ['eVar18'],
                    watchCtaData: ['eVar70'],
                    searchTerm: ['prop1', 'eVar1'],
                    numberSearchResultsDisplayed: ['eVar94'],
                    sid: ['eVar92'],
                    results: ['list1'],
                    redefinedSearchTerm: ['eVar93'],
                    adobeTntValuesOnClick: ['eVar86'],
                    prospectSections: ['eVar37'],
                    agentId: ['eVar59']
                },
                events: {
                    // CTAs
                    nevermissSuccess: 'event73',
                    recordSuccess: 'event71',
                    recordSeriesSuccess: 'event52',
                    downloadSuccess: 'event11',
                    videoPlayed: 'event21',
                    tileClicked: 'event131',
                    tileListEvent: 'event130',
                    watchClicked: 'event96',

                    // Search
                    searchSuccess: 'event15',
                    searchFailed: 'event26',
                    redefinedSearch: 'event204',
                    bouncedSearch: 'event203',
                    resultClicked: 'event202',

                    // Prospect Homepage
                    prospectTilesLoaded: 'event46',

                    // Customer statuses
                    standaloneBroadbandCustomer: 'event410',
                    tvOnlyCustomer: 'event411',
                    triplePlayCustomer: 'event412',
                    originalBundleCustomer: 'event413',
                    varietyBundleCustomer: 'event414',
                    familyBundleCustomer: 'event415',
                    broadbandLiteCustomer: 'event416',
                    broadbandUnlimitedCustomer: 'event417',
                    broadbandConnectCustomer: 'event418',
                    fibreCappedCustomer: 'event419',
                    fibreUnlimitedCustomer: 'event420',
                    sportsCustomer: 'event421',
                    moviesCustomer: 'event422',
                    hdSubscriptionCustomer: 'event423',
                    skyQStandardCustomer: 'event424',
                    skyQSilverCustomer: 'event425',
                    multiscreenCustomer: 'event426',
                    skyGoExtraCustomer: 'event427',

                    // Adobe Target
                    adobeTntValues: 'event196',
                    adobeTntValuesOnClick: 'event197'
                }
            }
        }
    }
};

[
    'nevermissSuccess',
    'recordSuccess',
    'recordSeriesSuccess',
    'downloadSuccess',
    'tileClicked',
    'watchClicked'
].forEach(eventName => {
    defaultConfig.adapters.adobeAnalytics.maps.populators[eventName] = event => get(event, 'element.custom.eventAction') === eventName;
});

module.exports = defaultConfig;
