const { find, merge, mergeDeepLeft, unnest, pipe, prop, propEq } = require('ramda');
const { removeIdentifiableData } = require('shared/user-profile-utils');

const Tracking = {
    pageLoad: () => { },
    elementRendered: () => { },
    bbStatus: 'unresolved',
    setExperiments: () => { },
    setDTMExperiments: () => { },
    setDTMJourneyType: () => { }
};

if (typeof window !== 'undefined') {
    const skyTags = window.skyTags = window.skyTags || { queue: [] };
    const elementToEvent = require('@sky-uk/sky-tags/src/builders/element-to-event');
    const push = (...args) => args.forEach(item => skyTags.queue.push(item));
    const init = () => push(['init']);
    const set = data => push(['set', data]);
    const pushEvent = evt => push(['event', evt]);
    const track = () => push(['track']);
    const send = evt => push(['event', evt], ['track']);

    const setPageCustom = custom => set({ page: { custom } });

    init();

    const getPageNameParts = (area, params) => {
        const PagesEngine = require('shared/pages').default;

        // episode and season are a smell here as it means
        // our tracking implementation is not generic enough
        const { slug, episode, season, subSlug, tab, customPageName, panelState, panelName } = params;
        const pagePartsDescription = [
            {
                condition: slug,
                value: ['panels', slug],
                suffix: [
                    {
                        condition: episode !== undefined && season !== undefined,
                        value: 'episodes'
                    },
                    {
                        condition: subSlug,
                        value: subSlug
                    }
                ]
            },
            {
                condition: tab,
                value: ['tabs', tab],
                suffix: [
                    {
                        condition: slug,
                        value: slug
                    }
                ]
            }
        ];

        const resolveParts = partsDesc => {
            if (partsDesc) {
                return pipe(find(prop('condition')), resolvePart)(partsDesc); // eslint-disable-line no-use-before-define
            } else {
                return [];
            }
        };

        const resolvePart = partDesc => {
            if (partDesc) {
                if (partDesc.condition) {
                    return [].concat(partDesc.value).concat(resolveParts(partDesc.suffix));
                }
            }
            return [];
        };

        const trackingConfig = PagesEngine.getArea(area).tracking;
        const areaParts = (trackingConfig && trackingConfig.root) || [area];
        const pagecrumb = trackingConfig && trackingConfig.pagecrumb;

        const pageParts = (pagecrumb && pagecrumb(params));
        const defaultParts = resolveParts(pagePartsDescription);
        const suffix = pageParts || (defaultParts.length > 0 && defaultParts) || 'unknown';

        const breadcrumb = [
            { condition: customPageName, value: customPageName, fallback: suffix },
            { condition: panelName, value: panelName },
            { condition: panelState, value: panelState }
        ]
            .reduce((acc, { condition, value, fallback }) => acc.concat(condition ? value : fallback), [])
            .filter(Boolean);

        return areaParts.concat(breadcrumb).map(el => el === 'mysky' ? 'myaccount' : el);
    };

    const getAdobeDetails = ({ offers = [] }) => {
        const tntValues = offers
            .filter(offer => offer.tntVal)
            .map(offer => offer.tntVal)
            .join(',');

        if (tntValues) {
            return { tntValues };
        }

        return {};
    };

    const getChannelFromData = (slug, tiles) => {
        const tile = find(propEq('slug', slug), tiles);

        if (tile && tile.brand) {
            return tile.brand.id;
        }
    };

    const transformGalleriesForTracking = (galleries, numberOfTilesOnPage) => {
        const tilesInGalleries = Array.isArray(galleries) ? galleries.map(gallery => gallery.collectionTiles).filter(tiles => tiles) : [];
        return tilesInGalleries.map((gallery, galleryIndex) => {
            return gallery.map((tile, tileIndex) => {
                const area = tile.brand ? tile.brand.id : tile.area;
                const galleryIndexOnPage = galleryIndex + numberOfTilesOnPage;
                return [`${galleryIndexOnPage}.${tileIndex}`, area, tile.gallerySlug, tile.slug].join(':');
            });
        });
    };

    const transformTilesForTracking = tiles => {
        const tilesOnPage = Array.isArray(tiles) ? tiles : [];
        return tilesOnPage.map((tile, index) => {
            const area = tile.brand ? tile.brand.id : tile.area;
            return [index, area, tile.slug].join(':');
        });
    };

    const transformItemsForTracking = (tiles, galleries) => {
        const tilesInfo = transformTilesForTracking(tiles);
        const galleryInfo = unnest(transformGalleriesForTracking(galleries, tilesInfo.length));
        return tilesInfo.concat(galleryInfo);
    };

    const buildPageData = ({ area, locationParams, tiles, metaData }) => {
        const pageParts = getPageNameParts(area, locationParams);

        const pageData = {
            name: pageParts[pageParts.length - 1],
            breadcrumb: pageParts.slice(0, pageParts.length - 1),
            url: {
                canonical: window.location.href.toString()
            },
            referrer: Tracking.previousURL,
            custom: {
                skyChannel: locationParams.channel || getChannelFromData(locationParams.slug, tiles) || '',
                helpProduct: locationParams.helpProduct || '',
                helpIntent: locationParams.helpIntent || '',
                panelName: locationParams.slug !== undefined ? locationParams.slug : '',
                flow: locationParams.flow || '',
                step: locationParams.step || '',
                currentSlide: locationParams.currentSlide || '',
                helpArticleSlug: locationParams.helpArticleSlug || '',
                metaData
            }
        };
        return pageData;
    };

    const createLinkEvent = ({ element, eventAction, locationParams, adobeTarget }) => {
        const event = elementToEvent(element);
        event.element.custom.panelName = locationParams.slug;
        event.element.custom.subSlug = locationParams.subSlug;
        event.element.custom.eventAction = eventAction;

        if (adobeTarget) {
            event.element.custom.adobeTarget = adobeTarget;
        }

        return event;
    };

    Tracking.set = set;

    Tracking.setExperiments = experiments => setPageCustom({ experiments });

    Tracking.setDTMExperiments = experiments => setPageCustom({
        variant: Object.keys(experiments).map(key => `${key}=${experiments[key]}`)
    });

    Tracking.setDTMJourneyType = journeyType => setPageCustom({ journeyType });

    Tracking.setAccountDashboardNBAs = nbas => {
        setPageCustom({ nbas: nbas.map(nba => nba.slug) });
    };

    Tracking.setAccountDashboardProductUpgrade = productUpgrade => {
        setPageCustom({ productUpgrade: productUpgrade.slug });
    };

    Tracking.setAccountDashboardPackageUpgrade = packageUpgrade => {
        setPageCustom({ packageUpgrade: packageUpgrade.slug });
    };

    Tracking.setCustomData = (custom) => {
        setPageCustom(custom);
    };

    Tracking.pageLoad = ({ area, locationParams, tiles = '', userProfile = '', galleries, metaData, adobeTarget = {} }) => {
        if (Tracking.previousURL === undefined) {
            Tracking.previousURL = document.referrer;
        }
        const filteredUserProfile = removeIdentifiableData(userProfile);

        const data = {
            page: buildPageData({ area, locationParams, tiles, metaData }),
            user: {
                custom: merge({
                    segment: {
                        userType: userProfile.isCustomer ? 'customer' : 'prospect'
                    }
                }, filteredUserProfile)
            }
        };

        const events = [{
            type: 'view',
            page: {
                custom: {
                    tileList: transformItemsForTracking(tiles, galleries),
                    adobeTarget: getAdobeDetails(adobeTarget)
                }
            }
        }];

        /*
         it is necessary to maintain our own history of this because in Chrome
         document.referrer does not reflect changes made through HTML5 history
         */
        Tracking.previousURL = data.page.url.canonical;

        set(data);
        events.forEach(pushEvent);
        track();
    };

    Tracking.setConfig = skyTagsConfig => {
        set({ config: skyTagsConfig });
    };

    Tracking.videoPlayed = slug => {
        const event = {
            type: 'click',
            custom: {
                video: slug
            },
            vendor: [{
                type: 'Adobe',
                events: ['videoPlayed']
            }]
        };

        send(event);
    };

    Tracking.searchView = (searchResults) => {
        send({
            type: 'search_view',
            searchResults
        });
    };

    Tracking.searchClick = (searchElement, target) => {
        send({
            type: 'search_click',
            searchElement,
            element: elementToEvent(target).element
        });
    };

    Tracking.searchSubmit = (form) => {
        send({
            type: 'search_submit',
            form,
            searchDetail: {
                itemname: '',
                uuid: '',
                searchString: '',
                keywords: '',
                rank: ''
            }
        });
    };

    Tracking.seeMoreArticles = articles => {
        const event = {
            type: 'click',
            page: {
                custom: {
                    articlesShown: articles.toString()
                }
            }
        };
        send(event);
    };

    Tracking.topicsShown = shown => {
        const event = {
            page: {
                custom: {
                    topics: `TOPICS=${shown}`
                }
            }
        };

        send(event);
    };

    Tracking.helpSearchShown = shown => {
        setPageCustom({
            variant: `HELP_SEARCH=${shown}`
        });
    };

    Tracking.contactUsPods = pods => {
        const event = {
            page: {
                custom: {
                    tileList: pods
                }
            }
        };
        send(event);
        set(event);
    };

    Tracking.offerList = (offers, sourceElement) => {
        if (typeof offers !== 'undefined' && offers !== null) {
            const event = mergeDeepLeft({
                type: 'click',
                page: {
                    custom: {
                        offerList: offers
                    }
                }
            }, sourceElement ? elementToEvent(sourceElement) : {});
            send(event);
        }
    };

    // XXX we always (apart from testing) use it with eventAction ==
    // 'linkClick': magical string? should we remove it from the API?
    Tracking.linkEvent = ({ element, eventAction, locationParams, adobeTarget }) => {
        send(createLinkEvent({ element, eventAction, locationParams, adobeTarget }));
    };

    Tracking.elementRendered = (elementType, elementData) => {
        const event = {
            type: 'elementRendered',
            page: {
                custom: {
                    elementType,
                    elementData
                }
            }
        };

        send(event);
    };

    Tracking.getPageName = (area, params) => {
        const pageParts = getPageNameParts(area, params);
        const breadCrumb = pageParts.slice(0, pageParts.length - 1);
        const name = pageParts[pageParts.length - 1];

        return `${breadCrumb}:${name}`;
    };

    Tracking.sendUserCustom = custom => send({ user: { custom } });

    Tracking.watchCtaEventGenerator = ({ element, eventAction, area, locationParams, episodeData }) => {
        const event = createLinkEvent({ element, eventAction, area, locationParams });
        event.element.custom.episodeData = episodeData;

        return {
            push() {
                send(event);
            }
        };
    };

    Tracking.clickEvent = (element) => {
        send(elementToEvent(element));
    };

    Tracking.updateQuote = (sessionId, quote) => {
        send({
            type: 'quote_update',
            quote: {
                id: sessionId,
                ...quote
            }
        });
    };

    Tracking.submitQuote = (orderId, sessionId, quote) => {
        send({
            type: 'quote_submit',
            orderId,
            quote: {
                id: sessionId,
                ...quote
            }
        });
    };

    Tracking.flowView = (customPageName) => {
        send({
            type: 'view',
            page: {
                name: customPageName,
                breadcrumb: ['help']
            }
        });
    };

    module.exports = Tracking;
}

module.exports = Tracking;
